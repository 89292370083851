import React from "react";
import { Grid } from "@nodeme/grid-react";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";
import logo from "../assets/logo.svg";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  nav: {
    backgroundColor: "#241f20",
    color: "#a1a3a6",
    height: 50,
  },
  link: {
    textDecoration: "none",
    color: "#a1a3a6",
    fontSize: "1.2rem",
    lineHeight: "47px",
    padding: "0px 16px",
    "&:hover": {
      color: "#fff",
    },
  },
  logo: {
    height: 50,
  },
});

function Header() {
  const classes = buildClasses(styles);
  return (
    <nav className={classes.nav}>
      <Grid spacing={8}>
        <Grid item md="auto">
          <a className={classes.link} href="https://artcare.at">
            <img className={classes.logo} src={logo} alt="artcare" />
          </a>
        </Grid>
        <Grid item xs="hidden" sm="hidden" md={"auto"}>
          <a className={classes.link} href="https://artcare.at/#about">
            Über Uns
          </a>
        </Grid>
        <Grid item xs="hidden" sm="hidden" md={"auto"}>
          <a className={classes.link} href="https://artcare.at/#works">
            Kunst
          </a>
        </Grid>
        <Grid item xs="hidden" sm="hidden" md={"auto"}>
          <a className={classes.link} href="https://artcare.at/#rent">
            Mieten
          </a>
        </Grid>
        <Grid item xs="hidden" sm="hidden" md={"auto"}>
          <a className={classes.link} href="https://artcare.at/#services">
            Service
          </a>
        </Grid>
        <Grid item xs="hidden" sm="hidden" md={"auto"}>
          <a className={classes.link} href="https://artcare.at/#contact">
            Kontakt
          </a>
        </Grid>
        <Grid item xs="hidden" sm="hidden" md={"auto"}>
          <a className={classes.link} href="https://artcare.at/#now">
            Auktionen
          </a>
        </Grid>
      </Grid>
    </nav>
  );
}

export default Header;
