import React from "react";
import { Grid } from "@nodeme/grid-react";
import logo4 from "../assets/artcareStd.png";
import logo5 from "../assets/derStandard.png";
import logo6 from "../assets/artcube.png";
import logo7 from "../assets/tokapi.png";
import { ITheme, IDefinitions, buildClasses } from "@nodeme/jss-react";

const styles = (theme: ITheme) => (definitions: IDefinitions) => {
  return {
    logo: {
      maxWidth: "100%",
    },
    logoHolder: {
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    logoHolder2: {
      paddingTop: "16px",
      textAlign: "center",
    },
    logoHolder3: {
      textAlign: "right",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
  };
};

function Footer() {
  const classes = buildClasses(styles);
  return (
    <footer>
      <Grid spacing={8} vertical justify="center">
        {/* <Grid item md={5}>
          <div className={classes.logoHolder2}>
            <img
              src={logo1}
              className={classes.logo}
              alt="wanna see your work"
            />
          </div>
        </Grid>
        <Grid item md={5}>
          <div className={classes.logoHolder2}>
            <img src={logo2} className={classes.logo} alt="ON THE COVER" />
          </div>
        </Grid> */}
        <Grid item xs="320px">
          <Grid spacing={8} vertical justify="center" alignItems="center">
            <Grid item xs="70px">
              <div className={classes.logoHolder2}>
                <a href="https://artcare.at">
                  <img src={logo4} className={classes.logo} alt="artcare" />
                </a>
              </div>
            </Grid>
            <Grid item xs="100px">
              <div className={classes.logoHolder2}>
                <a href="https://www.derstandard.at/">
                  <img src={logo5} className={classes.logo} alt="derStandard" />
                </a>
              </div>
            </Grid>
            <Grid item xs="80px">
              <div className={classes.logoHolder2}>
                <a href="https://tokapi.com/">
                  <img
                    src={logo7}
                    className={classes.logo}
                    alt="ON THE COVER"
                  />
                </a>
              </div>
            </Grid>
            <Grid item xs="50px">
              <div className={classes.logoHolder2}>
                <a href="https://artcube21.at/">
                  <img
                    src={logo6}
                    className={classes.logo}
                    alt="ON THE COVER"
                  />
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
